<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">About Me</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-3">
      <div class="text-center text-md-left">
        <!-- avatar image -->
        <img src="assets/images/imad-round.png" alt="Bolby" />
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-9 triangle-left-md triangle-top-sm">
      <div class="rounded bg-white shadow-dark padding-30">
        <div class="row">
          <div class="col-md-12">
            <!-- about text -->
            <p style="text-align: justify;">
              Are you looking for a senior Java developer who can create web solutions and business applications with high quality and agility? If yes, then you should check my CV.
            </p>
            <p style="text-align: justify;">
              I have been a software developer since 2017, specializing in Java-oriented technologies. I am a certified Scrum Master, Kubernetes Application Developer (CKAD), and a passionate advocate of Clean Code and design patterns. I have worked in various environments, from small companies to corporate data factories, and have adapted to different cultures and methodologies.
            </p>
            <p style="text-align: justify;">
              I currently work in France as an R&D engineer, developing innovative business applications. I want to join a dynamic team on exciting projects and contribute with my expertise and enthusiasm.
            </p>
            <p style="text-align: justify;">
              I am seeking a remote position as a Senior Software Engineer/Java Developer as a freelancer Or with a salary portage.
            </p>
            <div class="mt-3">
              <a href="assets/pdf/CV_IMAD_ALILAT_EN.pdf" class="btn btn-default" download>Download CV</a>
            </div>
            <div class="spacer-30 d-md-none d-lg-none"></div>
          </div>
          <!--
          <div class="col-md-6">
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Development</h4>
                <span class="float-right">85%</span>
              </div>
              <ngb-progressbar type="warning" [value]="85"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">UI/UX design</h4>
                <span class="float-right">95%</span>
              </div>
              <ngb-progressbar type="danger" [value]="95"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Photography</h4>
                <span class="float-right">70%</span>
              </div>
              <ngb-progressbar type="primary" [value]="70"></ngb-progressbar>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>
  <!-- row end -->

  <div class="spacer-70"></div>

  <div class="row">
        <div class="col-md-3 col-6">
          <!-- client item -->
          <div class="client-item">
            <div class="inner">
              <img [src]="'assets/images/java.svg'" alt="client-name" />
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <!-- client item -->
          <div class="client-item">
            <div class="inner">
              <img [src]="'assets/images/spring.svg'" alt="client-name" />
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <!-- client item -->
          <div class="client-item">
            <div class="inner">
              <img [src]="'assets/images/angular.svg'" alt="client-name" />
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <!-- client item -->
          <div class="client-item">
            <div class="inner">
              <img [src]="'assets/images/ts.svg'" alt="client-name" />
            </div>
          </div>
        </div>
        
        
        <div class="col-md-3 col-6">
          <!-- client item -->
          <div class="client-item">
            <div class="inner">
              <img [src]="'assets/images/k8s.svg'" alt="client-name" />
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <!-- client item -->
          <div class="client-item">
            <div class="inner">
              <img [src]="'assets/images/mongo.svg'" alt="client-name" />
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <!-- client item -->
          <div class="client-item">
            <div class="inner">
              <img [src]="'assets/images/agile.svg'" alt="client-name" />
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <!-- client item -->
          <div class="client-item">
            <div class="inner">
              <img [src]="'assets/images/docker.svg'" alt="client-name" />
            </div>
          </div>
        </div>
        
        
        
        
  </div>
  <!--
  <div class="row">
    <div class="col-md-3 col-sm-6">
      <div class="fact-item">
        <span class="icon icon-fire"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">198</em></h3>
          <p class="mb-0">Projects completed</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6">
      <div class="fact-item">
        <span class="icon icon-cup"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">5670</em></h3>
          <p class="mb-0">Cup of coffee</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6">
      <div class="fact-item">
        <span class="icon icon-people"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">427</em></h3>
          <p class="mb-0">Satisfied clients</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6">
      <div class="fact-item">
        <span class="icon icon-badge"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">35</em></h3>
          <p class="mb-0">Nominees winner</p>
        </div>
      </div>
    </div>
  </div>
  -->
</div>
