<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Study & certification</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-6">
      <!-- timeline wrapper -->
      <div class="timeline edu rounded bg-white shadow-dark padding-30 overflow-hidden">
        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">2014 - 2016</span>
            <h3 class="title">Master in Computer Science</h3>
            <p>Parallel Architecture, High Performance Computing and Development, University of Science and Technology Houari Boumediene (USTHB), Algeria</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
          <div class="content">
            <span class="time">2011 - 2014</span>
            <h3 class="title">Bachelor’s Degree</h3>
            <p>Academic Computing, Houari Boumediene University of Science and Technology (USTHB), Algeria</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
          <div class="content">
            <span class="time">2010 - 2011</span>
            <h3 class="title">Scientific Baccalaureate </h3>
            <p>Option applied sciences, Algeria</p>
          </div>
        </div>

        <!-- main line -->
        <span class="line"></span>
      </div>
    </div>

    <div class="col-md-6">
      <!-- responsive spacer -->
      <div class="spacer-30 d-md-none d-lg-none"></div>

      <!-- timeline wrapper -->
      <div class="timeline exp bg-white shadow-dark rounded padding-30 overflow-hidden">

         <!-- timeline item -->
         <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
          <div class="content">
            <span class="time">2023</span>
            <h4 class="title">CERTIFIED KUBERNETES APPLICATION DEVELOPEPR (CKAD)</h4>
            <p></p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">2022</span>
            <h4 class="title">CERTIFIED MIRAKL BUSINESS ANALYSTE</h4>
            <p></p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
          <div class="content">
            <span class="time">2022</span>
            <h4 class="title">CERTIFIED PROFESSIONAL SCRUM MASTER</h4>
            <p></p>
          </div>
        </div>

        <!-- main line -->
        <span class="line"></span>
      </div>
    </div>
  </div>
</div>
