<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Experience</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-6">
      <!-- timeline wrapper -->
      <div class="timeline exp rounded bg-white shadow-dark padding-30 overflow-hidden">
        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">2021 - Present</span>
            <h4 class="title">Software Engineer, Publics Sapient</h4>
            <p style="text-align: justify;">I  have had the privilege of working with industry- leading clients such as Sonepar and Ingenico. My expertise allows me to deliver tailor-made solutions that align with their business specifications.</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
          <div class="content">
            <span class="time">2021</span>
            <h4 class="title">Java Developer, Adie</h4>
            <p style="text-align: justify;">I collaborated with the internal and external team in improving existing systems and in maintenance.</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">2021</span>
            <h4 class="title">Full Stack Developer, Cooptalis</h4>
            <p style="text-align: justify;">I collaborated with two software architects for the development of documentation for existing systems.</p>
          </div>
        </div>

        <!-- main line -->
        <span class="line"></span>
      </div>
    </div>

    <div class="col-md-6">
      <!-- responsive spacer -->
      <div class="spacer-30 d-md-none d-lg-none"></div>

      <!-- timeline wrapper -->
      <div class="timeline exp bg-white shadow-dark rounded padding-30 overflow-hidden">
        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">2018 - 2020</span>
            <h4 class="title">Full Stack Developer, Cevital Groupe</h4>
            <p style="text-align: justify;">During this period, my tasks varied greatly initially; I developed new features, fixed production bugs, then I participated in the development of a B2B platform for Brandt.</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
          <div class="content">
            <span class="time">2017 - 2018</span>
            <h4 class="title">Full-Stack Developer, DEVALIT</h4>
            <p style="text-align: justify;">Work on building a B2C platform ”Lepromobilier” for furniture professionals in Algeria.</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
          <div class="content">
            <span class="time">2016 - 2017</span>
            <h4 class="title">Development Engineer , Algérie Télécom</h4>
            <p style="text-align: justify;">During this period, I worked on building a computer solution for the 3 call centers of Algeria Telecom.</p>
          </div>
        </div>

        <!-- main line -->
        <span class="line"></span>
      </div>
    </div>
  </div>
</div>
