<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Get In Touch</h2>

  <div class="spacer-60"></div>
  <div class="row">
    <div class="col-md-12" style="display: flex; flex-direction: column; align-items: center;">
      <!-- contact info -->
      <div class="contact-info">
        <h3 class="wow fadeInUp">Let's talk about everything!</h3>
        <p class="wow fadeInUp">Don't like forms? <a href="https://meet.alilat.dev/book/job-meeting/imad-interview" target="_blank">Book a call</a> or send me an <a href="mailto:alilat.imad@gmail.com">email</a>. 👋</p>
      </div>
      <div class="column col-md-12">
        <button type="submit" name="submit" id="submit" value="Submit" class="btn btn-default btn-block"
          onclick="window.open('https://meet.alilat.dev/book/job-meeting/imad-interview', '_blank')">
          <i class="fa fa-calendar"></i>  Book a call 
        </button>
      </div>
    </div>
      
    <div class="col-md-12">
      <!-- Contact Form -->
      <iframe
      height="450" allowTransparency="true"
      frameborder="0" scrolling="yes"
      style="width:100%;border:none"
      src="https://forms.alilat.tech/contact"
      id="formalooIframe"></iframe>
      <!-- Contact Form end -->
    </div>
  </div>
</div>
